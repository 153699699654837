<!--
 * @Author: mulingyuer
 * @Date: 2021-09-16 18:06:12
 * @LastEditTime: 2021-10-21 16:56:26
 * @LastEditors: mulingyuer
 * @Description: 会员画像
 * @FilePath: \saas-admin-vue\src\modules\gov\components\bid-data\MemberPortrait.vue
 * 怎么可能会有bug！！！
-->
<template>
  <BDCard title="会员画像" :loading="loading">
    <template #headRight>
      <MyDatePicker
        v-model="form.time"
        ref="MyDatePicker"
        @change="getUserGrowth"
      />
    </template>
    <div class="member-portrait">
      <div class="head"></div>
      <div class="content" ref="chartContent"></div>
    </div>
  </BDCard>
</template>

<script>
import BDCard from '@/modules/gov/layout/big-data/BDCard.vue'
import MyDatePicker from '@/modules/gov/components/bid-data/MyDatePicker.vue'
import { consultUserStats } from '@/modules/consult/api/consult-stats/index.js'
import MyPieChart from '@/modules/gov/assets/echarts/pie-chart'
export default {
  data() {
    return {
      loading: false, //加载中
      form: {
        time: [-1, -1],
        // start_time: -1, //开始时间
        // end_time: -1, //结束时间
      },

      $chart: null, //图表实例
      //图表数据
      chartData: [],
    }
  },
  methods: {
    //问答用户统计
    getUserGrowth() {
      this.loading = true
      const postData = { ...this.form }
      consultUserStats(postData)
        .then((res) => {
          const { data } = res
          this.chartData = data

          this.transformData() //转换数据格式
          this.initChart() //初始化
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    //转换数据格式
    transformData() {
      // this.chartData = this.chartData.map(({ name, count }) => ({
      //   name,
      //   value: count,
      // }))
      this.chartData = [
        {
          name: '发问用户数量',
          value: this.chartData.content_user_count,
        },
        {
          name: '回答用户数量',
          value: this.chartData.comment_user_count,
        },
      ]
      console.log(this.chartData)
    },
    //初始化图表
    initChart() {
      if (!this.$chart) {
        this.$nextTick(() => {
          this.$chart = new MyPieChart({
            container: this.$refs.chartContent,
            data: this.chartData,
            series: {
              radius: ['60%', '80%'],
              center: ['30%', '50%'],
            },
          })
        })
      } else {
        this.setChartOption()
      }
    },
    //设置图表
    setChartOption() {
      this.$chart.setOption(this.chartData)
    },
    //销毁实例
    destroyChart() {
      if (this.$chart) {
        this.$chart.dispose()
      }
    },
  },
  created() {
    this.getUserGrowth()
  },
  beforeDestroy() {
    this.destroyChart()
  },
  components: {
    BDCard,
    MyDatePicker,
  },
}
</script>

<style lang="scss" scoped>
.member-portrait {
  height: 100%;
  display: flex;
  flex-direction: column;
  .head {
    flex-shrink: 0;
    margin-bottom: 10px;
  }
  .content {
    min-width: 0;
    flex-grow: 1;
    min-height: 300px;
  }
}
</style>
