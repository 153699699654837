<template>
  <CommonCard title="未通过" :data="cardData"></CommonCard>
</template>

<script>
import CommonCard from './CommonCard.vue'
import { uncheckCount } from '@/modules/consult/api/consult-stats/index.js'
export default {
  props: {},
  data() {
    return {
      cardData: {},
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 获取数据
    getData() {
      uncheckCount().then((res) => {
        console.log(res)
        this.cardData = res.data
      })
    },
  },
  components: {
    CommonCard,
  },
}
</script>

<style lang="scss" scoped></style>
