<!--
 * @Author: mulingyuer
 * @Date: 2021-09-02 17:51:44
 * @LastEditTime: 2021-09-02 18:20:48
 * @LastEditors: mulingyuer
 * @Description: 设置
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\views\settings.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="consult-stats">
    <div class="base-card-list">
      <el-row :gutter="10">
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CardContentCount></CardContentCount>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CardCommentCount></CardCommentCount
        ></el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CardCommentUserCount></CardCommentUserCount
        ></el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CardContentUserCount></CardContentUserCount
        ></el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CardMostContentCategory></CardMostContentCategory>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CardCheckedCount></CardCheckedCount>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CardCheckingCount></CardCheckingCount>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CardUncheckCount></CardUncheckCount
        ></el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <ChartContentStats></ChartContentStats>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <ChartCommentStats></ChartCommentStats>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
          <ChartContentCategoryStats></ChartContentCategoryStats>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
          <ChartConsultUserStats></ChartConsultUserStats>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
// 问题数量
import CardContentCount from '@/modules/consult/components/consult-stats/CardContentCount.vue'
// 回答数量
import CardCommentCount from '@/modules/consult/components/consult-stats/CardCommentCount.vue'
// 回答人数
import CardCommentUserCount from '@/modules/consult/components/consult-stats/CardCommentUserCount.vue'
// 发问人数
import CardContentUserCount from '@/modules/consult/components/consult-stats/CardContentUserCount.vue'
// 提问最多分类
import CardMostContentCategory from '@/modules/consult/components/consult-stats/CardMostContentCategory.vue'
// 已审核
import CardCheckedCount from '@/modules/consult/components/consult-stats/CardCheckedCount.vue'
// 未通过
import CardUncheckCount from '@/modules/consult/components/consult-stats/CardUncheckCount.vue'
// 待审核
import CardCheckingCount from '@/modules/consult/components/consult-stats/CardCheckingCount.vue'
// 问题统计
import ChartContentStats from '@/modules/consult/components/consult-stats/ChartContentStats.vue'
// 回答统计
import ChartCommentStats from '@/modules/consult/components/consult-stats/ChartCommentStats.vue'
// 分类统计
import ChartContentCategoryStats from '@/modules/consult/components/consult-stats/ChartContentCategoryStats.vue'
// 用户统计
import ChartConsultUserStats from '@/modules/consult/components/consult-stats/ChartConsultUserStats.vue'

export default {
  data() {
    return {}
  },
  methods: {},
  created() {},
  components: {
    CardContentCount,
    CardCommentCount,
    CardCommentUserCount,
    CardContentUserCount,
    CardMostContentCategory,
    CardCheckedCount,
    CardUncheckCount,
    CardCheckingCount,
    ChartCommentStats,
    ChartContentStats,
    ChartContentCategoryStats,
    ChartConsultUserStats
  },
}
</script>

<style lang="scss" scoped>
.consult-stats {
  .base-card-list {
    .el-col {
      margin-bottom: 10px;
    }
  }
}
</style>
