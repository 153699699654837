<template>
  <div class="data-item">
    <div class="head">
      <h2 class="title">待审核</h2>
      <div class="filter">
        <el-tag>今日</el-tag>
      </div>
    </div>
    <div class="body-box">
      <div class="content-show">
        <div class="strong">{{ cardData.count || 0 }}</div>
        <span></span>
      </div>
      <div class="filter-box"></div>
    </div>
    <div class="button-box" @click="goChecking">前往审核</div>
  </div>
</template>

<script>
import CommonCard from './CommonCard.vue'
import { checkingCount } from '@/modules/consult/api/consult-stats/index.js'
export default {
  props: {},
  data() {
    return {
      cardData: {},
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 获取数据
    getData() {
      checkingCount().then((res) => {
        console.log(res)
        this.cardData = res.data
      })
    },
    // 去审核
    goChecking() {
      this.$router.push({ name: 'ConsultContentList' });
    },
  },
  components: {
    CommonCard,
  },
}
</script>

<style lang="scss" scoped>
.data-item {
  padding: 20px;
  font-family: 'PingFang SC';
  background-color: #fff;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      color: '1a1a1a';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }

    .filter {
    }
  }
  .body-box {
    margin-top: 12px;
    .content-show {
      display: flex;
      align-items: center;
      .strong {
        color: #1a1a1a;

        font-size: 24px;
        font-weight: 500;
      }
      span {
        margin-left: 8px;
        color: #808080;
        font-size: 14px;
      }
    }

    .filter-box {
      margin-top: 8px;
      color: #1a1a1a;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      min-height: 20px;
      .time-box {
      }

      .chain-ratio {
        margin-left: 24px;
        display: flex;
        align-items: center;
        .number {
          color: #13ce66;
          font-size: 14px;
          font-weight: 700;
        }

        .icon {
          margin-left: 5px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  .line {
    width: 100%;
    background-color: rgba(#000, 0.1);
    height: 1px;
    margin: 16px 0;
  }
  .footer {
    color: #1a1a1a;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .footer-left {
    }

    .footer-right {
      span {
        color: #808080;
        font-size: 11rpx;
        margin-left: 4px;
      }
    }
  }
  .button-box {
    background-color: rgba(#1a75ff, 0.05);
    color: #1a75ff;
    font-weight: 500;
    font-size: 14rpx;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
