<!--
 * @Author: mulingyuer
 * @Date: 2021-09-17 16:02:13
 * @LastEditTime: 2021-10-21 17:54:54
 * @LastEditors: mulingyuer
 * @Description: 会员增长趋势
 * @FilePath: \saas-admin-vue\src\modules\gov\components\bid-data\MemberGrowth.vue
 * 怎么可能会有bug！！！
-->
<template>
  <BDCard title="问题统计" :loading="loading">
    <template #headRight>
      <MyDatePicker
        v-model="form.time"
        ref="MyDatePicker"
        @change="getUserGrowth"
      />
    </template>
    <div class="member-growth">
      <div class="head"></div>
      <div class="content" ref="chartContent"></div>
    </div>
  </BDCard>
</template>

<script>
import BDCard from '@/modules/gov/layout/big-data/BDCard.vue'
import AssociationSelect from '@/modules/gov/components/bid-data/AssociationSelect.vue'
import MyDatePicker from '@/modules/gov/components/bid-data/MyDatePicker.vue'
import MyLineChart from '@/modules/gov/assets/echarts/line-chart.js'
import { contentStats } from '@/modules/consult/api/consult-stats/index.js'
export default {
  data() {
    return {
      loading: false, //加载中
      form: {
        project_id: -1, //协会id
        time: [-1, -1],
        // start_time: -1, //开始时间
        // end_time: -1, //结束时间
      },
      $chart: null, //图表实例
      userGrowthData: {}, //api获取的数据
      //图表数据
      chartData: [],
      //x轴坐标数据
      xData: [],
    }
  },
  methods: {
    //获取会员增长趋势
    getUserGrowth() {
      this.loading = true
      const postData = this.getFormData()
      contentStats(postData)
        .then((res) => {
          const { data } = res
          this.userGrowthData = data.list

          this.transformData()
          this.initChart()
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    //转换数据格式
    transformData() {
      this.chartData = this.userGrowthData.map((item) => item.count)
      this.xData = this.userGrowthData.map((item) => item.day_time)
      console.log(this.chartData,this.xData)
    },
    //获取提交的form数据
    getFormData() {
      const data = { ...this.form }
      const [start_time = '', end_time = ''] = data.time
      Object.assign(data, { start_time, end_time })
      Reflect.deleteProperty(data, 'time')
      return data
    },
    //初始化图表
    initChart() {
      if (!this.$chart) {
        this.$chart = new MyLineChart({
          container: this.$refs.chartContent,
          data: this.chartData,
          xData: this.xData,
        })
      } else {
        this.setChartOption()
      }
    },
    //设置图表
    setChartOption() {
      this.$chart.setOption(this.xData, this.chartData)
    },
    //销毁实例
    destroyChart() {
      if (this.$chart) {
        this.$chart.dispose()
      }
    },
  },
  created() {
    this.loading = true
    this.$nextTick(() => {
      //获取时间默认值
      this.$refs['MyDatePicker'].updateTime()
      //发起请求
      this.getUserGrowth()
    })
  },
  beforeDestroy() {
    this.destroyChart()
  },
  components: {
    BDCard,
    MyDatePicker,
    AssociationSelect,
  },
}
</script>

<style lang="scss" scoped>
.member-growth {
  height: 100%;
  display: flex;
  flex-direction: column;
  .head {
    flex-shrink: 0;
    margin-bottom: 10px;
  }
  .content {
    height: 300px;
    min-width: 0;
    flex-grow: 1;
  }
}
</style>
